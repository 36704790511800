<template>
    <div
        @click="$emit('close')"
        class="aggrement-popup"
    >
        <div
            @click.stop=""
            class="aggrement-popup__block"
        >
            <div v-html="acceptanceText"></div>
            <div v-html="autoPaymentText"></div>
            <Button
                class="aggrement-popup__button"
                @click.native="$emit('close')"
            >
                Ознакомлен
            </Button>
        </div>
    </div>
</template>

<script>

import sbg from '@index/mixins/sbg'
import Cookies from 'js-cookie'
import Button from '@/components/gui/button/Button'

export default {
    name: 'AggrementPopup',
    mixins: [
        sbg
    ],
    computed: {
        acceptanceText() {
            if (!Cookies.get('sbg-in'))
                return this.$DICTIONARY.acceptanceTextFirst || this.$DICTIONARY.acceptanceText

            return this.$DICTIONARY.acceptanceText
        },
        autoPaymentText() {
            return this.$DICTIONARY.acceptanceAutoPayment
        }
    },
    created() {
        document.body.overflow = 'hidden'
    },
    beforeDestroy() {
        document.body.overflow = 'auto'
    },
    components: {
        Button
    }
};
</script>

<style lang="scss">
.aggrement-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#000, .6);
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__block {
        max-width: 400px;
        padding: 40px;
        box-sizing: border-box;
        margin: 0 auto;
        text-align: center;
        background: #FFF;
        border-radius: 20px;
        font-size: 16px;
        line-height: 1.4;

        @media(max-width: 700px) {
            padding: 24px;
        }

        a {
            color: #02b685;
        }
    }

    &__button {
        margin-top: 30px;
        width: 100%;
    }
}
</style>